import React, {useEffect, useState} from "react"
import {clsx} from "clsx"
// import step1Img from "@metronic/Components/Securities/images/Step_1.png"
// import step2Img from "@metronic/Components/Securities/images/step_2.png"
// import step3Img from "@metronic/Components/Securities/images/step_3.png"
import {useSecuritiesContext} from "@metronic/Components/Securities/Context/SecuritiesContext.jsx"

export const stepsImages = (blockId, activeStepTab) => {
  const {tabData} = useSecuritiesContext()
  const [data, setData] = useState([])

  useEffect(() => {
    if (tabData && blockId) {
      setData(tabData.find(i => i.uuid === blockId)?.steps_tabs_list)
    }
  }, [tabData, blockId])

  return <>
    {(data && data.length) ?
      data.map((item, idx) => {
        return (
          <img
            key={idx}
            className={clsx('mw-225px ps-0 ps-md-2', activeStepTab === item.uuid ? 'd-block' : 'd-none')}
            src={item.img}
            alt=""
          />
        )}
      ) : null
    }
  </>
}

export const allListLink = () => {
  const {activeTab, setActiveTab, allListTab, tableRef} = useSecuritiesContext()

  const handleClick = (e) => {
    e.preventDefault()

    if (!allListTab) return

    if (allListTab !== activeTab) {
      setActiveTab(allListTab)
    }

    if (allListTab && tableRef.current) {
      setTimeout(() => {
        tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'center'})
      }, 200)
    }
  }

  return <div className="py-3 mb-5">
    <a
      href="#"
      className="text-success fs-4 btn btn-active-light-success ps-2 pe-0 py-0 m-0"
      onClick={(e) => handleClick(e)}
    >
      List of all bonds <i className="bi bi-chevron-right fs-4 ps-1 text-success"></i>
    </a>
  </div>
}

export const blockCardTitle = (title) => {
  return (
    <>
      <h2 className="text-gray-700">{title}</h2>
      <div className="separator mb-4 border-1 border-gray-500"></div>
    </>
  )
}

export const TablePagination = (props) => {
  const {
    securitiesList,
    currentPage,
    recordsPerPage,
    setCurrentRecords,
    setTotalPages,
    blockData
  } = props

  useEffect(() => {
    if (securitiesList && securitiesList.length) {
      const indexOfLastRecord = currentPage * recordsPerPage
      const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
      let currentList

      if (securitiesList && securitiesList.length) {
        currentList = securitiesList
      } else {
        currentList = []
      }

      setCurrentRecords(currentList.slice(indexOfFirstRecord, indexOfLastRecord))
      setTotalPages(Math.ceil(currentList.length / recordsPerPage))
    }
  }, [securitiesList, currentPage, blockData])

  return null
}

export function handlePrevPage (setCurrentPage)  {
  return setCurrentPage(prevPage => Math.max(prevPage - 1, 1))
}

export function handleNextPage (setCurrentPage, totalPages) {
  return setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages))
}
